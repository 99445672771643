<template>
    <div>
      <div class="mx-auto px-6 sm:px-6 py-4">
        <div class="py-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>
              <h2 class="text-2xl font-semibold leading-tight">{{titlePage}}</h2>
            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'Users',params: {pageno:pageno,perpage: perPage,order:currentSortRoute,search:search} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
          <div class="shadow-md">
            <form @submit.prevent="validateBeforeSubmit" class="form" id="frm">
              <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-gray-400 rounded-t"></div>
              <div class="bg-white space-y-6">
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="company_code">
                        Company *
                      </label>
                      <div class="relative">
                        <select name="company_code" v-model="detail.company_code" v-validate="'required'" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('company_code') }">
                          <option value=""></option>
                          <option v-for="item in company" :value="item.code">{{item.name}}</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('company_code')">{{errors.first('company_code')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="user_name">
                        Username *
                      </label>
                      <input name="user_name" v-model="detail.user_name" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('user_code') }" id="user_name" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('user_name')">{{errors.first('user_name')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="display_name">
                        Name *
                      </label>
                      <input name="display_name" v-model="detail.display_name" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('display_name') }" id="display_name" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('display_name')">{{errors.first('display_name')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="email">
                        Email *
                      </label>
                      <input name="name" v-model="detail.email" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('email') }" id="email" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('email')">{{errors.first('email')}}</p>
                    </div>
                  </div>
                  <div class="pb-4">
                    <h4 class="font-semibold">Details</h4>
                    <hr>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="phone">
                        Phone
                      </label>
                      <input name="phone" v-model="detail.phone" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('phone') }" id="phone" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('phone')">{{errors.first('phone')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="title">
                        Title
                      </label>
                      <input name="title" v-model="detail.title" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('title') }" id="title" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('title')">{{errors.first('title')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="state">
                        Group
                      </label>
                      <div class="relative">
                        <select name="company_code" v-model="detail.primary_group_id" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('primary_group_id') }">
                          <option value=""></option>
                          <option v-for="item in role" :value="item.id">{{item.name}}</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('primary_group_id')">{{errors.first('primary_group_id')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3 pt-6 mb-6 md:mb-0">
                      <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input type="checkbox" name="add_go_user" v-model="detail.add_go_user" value="1" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                        <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                      </div>
                      <label for="toggle" class="text-xs text-gray-700 pr-3">Add Go User</label>
                      <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input type="checkbox" name="add_go_user" v-model="detail.flag_enabled" value="1" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                        <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                      </div>
                      <label for="toggle" class="text-xs text-gray-700 pr-3">Enabled</label>
                      <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input type="checkbox" name="add_go_user" v-model="detail.flag_verified" value="1" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                        <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                      </div>
                      <label for="toggle" class="text-xs text-gray-700 pr-3">Verified</label>
                    </div>
                  </div>
                  <div class="pb-4">
                    <h4 class="font-semibold">VPN</h4>
                    <hr>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/3 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="phone">
                        ID
                      </label>
                      <input name="phone" disabled v-model="detail.pritunl.id" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('pritunl.id') }" id="phone" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('pritunl.id')">{{errors.first('pritunl.id')}}</p>
                    </div>
                    <div class="md:w-1/3 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="title">
                        PIN
                      </label>
                      <input name="title" disabled v-model="detail.pritunl.pin" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('title') }" id="title" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('title')">{{errors.first('title')}}</p>
                    </div>
                    <div class="md:w-1/3 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="title">
                        VITUAL ADDRESS
                      </label>
                      <input name="title" disabled v-model="detail.pritunl.virt_address" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('title') }" id="title" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('title')">{{errors.first('title')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>

            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'Users',params: {pageno:pageno,perpage: perPage,order:currentSortRoute,search:search} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from 'qs';
import { AUTH_URL } from "actions/auth";
import { Validator } from 'vee-validate';
import { ModelSelect,ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

const dict = {
  custom: {
    company_code: {
      required: 'Your company owner is empty'
    },
    user_name: {
      required: () => 'Username is empty'
    },
    display_name: {
      required: 'Name is empty'
    },
    email: {
      required: 'Email is empty'
    }
  }
};

Validator.localize('en', dict);

const methods = {
  validateBeforeSubmit(e){
    this.$router.push({name: 'Users',params: {pageno:this.pageno,perpage: this.perPage,order:this.currentSortRoute,search:this.search} })
    /*this.$validator.validateAll().then((result) => {
      if (result) {
          let myForm = document.getElementById('frm');
          let formData = new FormData(myForm);
          const data = {};
          for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
          }
          if(this.$route.params.id!='' && this.$route.params.id!=undefined){
            var metodo = 'PUT';
            Object.assign(data, {id: this.$route.params.id})
          }else{
            var metodo = 'POST';
          }
          axios({url: AUTH_URL+'master/api/v2/users', data: qs.stringify(data), method: metodo ,
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem("user-token"),
              'Accept' : '*\/*',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
              }
            })
            .then(resp => {
              //console.log(resp.data)
            })
            .catch(err => {
              this.error = true;
              console.error(err)
          })
          //this.$router.push("/users");
        return;
      }
    });*/
  }
};

export default {
  name: 'usersDetail',
  data() {
    return {
      titlePage: 'New User',
      currentId: 0,
      detail: [],
      pageno: this.$route.params.pageno,
      perPage: this.$route.params.perpage,
      currentSortRoute: this.$route.params.order,
      search : this.$route.params.search,
      company: [],
      companyname: '',
      groups:[],
      company_code: '',
      user_name: '',
      uic: '',
      name: '',
      address: '',
      cap: '',
      city: '',
      state: '',
      notes: '',
      currentPage:1,
      currentSort:'owner',
      currentSortDir:'asc',
      role: []
    };
  },
  components:{
    Validator,
    ModelSelect,
    ModelListSelect
  },
  computed:{

  },
  methods,
  mounted: function mounted(){

  },
  beforeMount: function beforeMount(){
    if(this.$route.params.id!='' && this.$route.params.id!=undefined){
      this.titlePage = 'Edit User';
      this.currentId = this.$route.params.id;
      this.detail = this.$route.params.dati
      //this.pageSize = this.$route.params.pagepage
    }
    axios({url: AUTH_URL+'master/api/v2/company', method: 'GET' ,
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.company = resp.data;
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
    axios({url: AUTH_URL+'master/api/v2/groups', method: 'GET' ,
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.role = resp.data;
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
  }
}
</script>
<style>
.ui.selection.dropdown {
  min-height:50px;
}
.ui.selection.dropdown i{display:none;}
.ui.selection.dropdown .text{
  top:8px;
}
.ui.selection.dropdown input{
  top:8px;
}
</style>